import { AxiosError } from 'axios'
import get from 'lodash/get'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Chevron from '../Chevron'
import Alignment from '../Alignment'
import ConsultTableTypeCell from './ConsultTableTypeCell'
import { assignConsult } from 'services/consultsApi'
import { AddressType, ConsultStatusLog, CustomerType } from '../../types/customerType'
import {
  ConsultActionRequiredEnum,
  ConsultScopesEnum,
  ConsultStatusEnum,
  ConsultType,
  ConsultTypeEnum,
  FinishedStatuses,
} from '../../types/consultType'
import { SortOrderDirection } from '../../types/genericTypes'
import { differenceInDays, differenceInMinutes } from 'date-fns'
import Button from '../Button'
import Modal from '../Modals/Modal'
import { NextRouter, useRouter } from 'next/router'
import { DateFormatEnum, formatDate, sortByTime, TimeFormatEnum } from '../../utils/dateTime'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import { TableCell as MuiTableCell } from '@mui/material'
import TableBody from '@mui/material/TableBody'
import { makeStyles } from '@mui/styles'
import theme from '../../styles/theme'
import CircularProgress from '@mui/material/CircularProgress'
import { ClassNameMap } from '@mui/styles'

const EMPTY_INITIAL =
  'There are no available initial consults for your licensed state(s) in the Initial Consult Waiting Room at this time.'
const EMPTY_RX_EXPRESS_INITIAL =
  'There are no available Rx Express initial consults for your licensed state(s) in the  Rx Express initial consults Waiting Room at this time.'
const useStyles = makeStyles(() => ({
  status: {
    '&&': {
      fontWeight: 600,
      '& .MuiButtonBase-root': {
        marginLeft: '24px',
      },
    },
  },
  table: {
    '&&': { width: '100%', background: theme.colors.white, borderSpacing: 0 },
  },
  header: {
    '&&': { fontWeight: 600 },
  },
  tableRow: {
    '&&': {
      cursor: 'pointer',
      '& .MuiTableCell-root': {
        fontSize: '15px',
      },
    },
  },
  loading: {
    '&&': { color: theme.colors.primaryGreen },
  },
  text: {
    background: theme.colors.white,
    color: theme.colors.midGrey6,
    fontWeight: 700,
    padding: '32px 16px',
    width: '100%',
    fontFamily: 'Roboto',
  },
}))
export type TableColumnType = {
  text: string
  key: string
  formatter?: (data: any, status: ConsultStatusEnum) => string | React.ReactElement
  nestedPath?: (data: any) => Record<string, unknown>
  isSortable?: boolean
}

interface ConsultsTableProps {
  page: number
  pageSize: number
  data: Array<ConsultType>
  totalCount: number
  isLoading: boolean
  scope: string
  consultRefreshRate: number
  getConsults: ({
    page,
    sortDirection,
    sortBy,
  }: {
    page?: number
    sortDirection: SortOrderDirection
    sortBy: string
  }) => void
}

const Container = styled.div`
  padding-right: 10px;
  font-size: 15px;
`

export const EnhancedTableHead = ({
  sortDirection,
  columns,
  sortBy,
  handleSortRequest,
  classes,
}: {
  sortDirection: SortOrderDirection
  columns: Array<TableColumnType>
  sortBy: string
  handleSortRequest: (key: string) => void
  classes?: ClassNameMap<'table' | 'status' | 'header' | 'tableRow' | 'loading'>
}): JSX.Element => {
  const sortDir = sortDirection === SortOrderDirection.ASC ? 'asc' : 'desc'
  return (
    <TableHead>
      <TableRow>
        {columns.map(headCell => (
          <MuiTableCell
            key={headCell.text}
            align={headCell.key === 'lastName' ? 'left' : 'center'}
            padding={'normal'}
            onClick={() => headCell.isSortable && handleSortRequest(headCell.key)}
            className={headCell.key === 'status' ? classes?.status : classes?.header}
          >
            {headCell.isSortable ? (
              <TableSortLabel
                style={{ marginLeft: headCell.key === 'state' ? '24px' : '0px' }}
                active={sortBy === headCell.key}
                direction={sortDir}
              >
                {headCell.text}
              </TableSortLabel>
            ) : (
              headCell.text
            )}
          </MuiTableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export const TableCell = (props: {
  text: string
  data: any
  formatter?: any
  status?: ConsultStatusEnum
  priority?: number
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify' | undefined
}): JSX.Element => {
  const { data, formatter, status, text, align } = props
  // Styles specific to Consult Type cell
  if (text === 'Consult Type') {
    return (
      <ConsultTableTypeCell type={data as ConsultTypeEnum} priority={props.priority}>
        {formatter ? formatter(data, status) : data}
      </ConsultTableTypeCell>
    )
  }
  const cellAlign = align || 'center'
  return <MuiTableCell align={cellAlign}>{formatter ? formatter(data, status) : data}</MuiTableCell>
}

export const EnhancedTableContent = ({
  consultData,
  columns,
  classes,
  router,
  setIsLoading,
  shouldAssignConsult,
  handleError,
}: {
  consultData: Array<ConsultType>
  columns: Array<TableColumnType>
  setIsLoading?: (val: boolean) => void
  router?: NextRouter
  shouldAssignConsult: boolean
  handleError: (reason: Error) => void
  classes?: ClassNameMap<'table' | 'status' | 'header' | 'tableRow' | 'loading'>
}): JSX.Element => {
  return (
    <TableBody>
      {consultData.map((row: ConsultType) => {
        return (
          <TableRow
            className={classes?.tableRow}
            hover
            key={row.id}
            onClick={() => {
              setIsLoading && setIsLoading(true)
              if (shouldAssignConsult) {
                assignConsult({ consultId: row.id })
                  .then(() => {
                    router && router.push(`/consult/${row.id}`)
                  })
                  .catch(handleError)
              } else {
                router && router.push(`/consult/${row.id}`)
              }
            }}
          >
            {columns.map((obj: TableColumnType) => {
              if (obj['nestedPath']) {
                const path = obj.nestedPath(row)
                return (
                  <TableCell
                    align={obj.key === 'lastName' ? 'left' : 'center'}
                    {...obj}
                    formatter={obj?.formatter}
                    status={row.status}
                    priority={row.priority}
                    data={path}
                    key={obj.key}
                  />
                )
              }
              return (
                <TableCell
                  align={obj.key === 'lastName' ? 'left' : 'center'}
                  {...obj}
                  formatter={obj?.formatter}
                  status={row.status}
                  priority={row.priority}
                  data={get(row, obj.key)}
                  key={obj.key}
                />
              )
            })}
          </TableRow>
        )
      })}
    </TableBody>
  )
}

const actionCellFormatter = (data: string) => (
  <div
    style={{
      color: theme.colors.white,
      backgroundColor:
        data == ConsultActionRequiredEnum.Reply
          ? theme.colors.midBlue
          : data == ConsultActionRequiredEnum.LabsReview
          ? theme.colors.lightPurple
          : theme.colors.white,
      borderRadius: 4,
      padding: 5,
    }}
  >
    {data == ConsultActionRequiredEnum.Reply
      ? 'Reply'
      : data == ConsultActionRequiredEnum.LabsReview
      ? 'Review Labs'
      : ''}
  </div>
)
const CONSULT_SORTING_SETTING = `consultSortingOrder`
export const getSortingSetting = (scope: string) => {
  const sortString = localStorage.getItem(CONSULT_SORTING_SETTING)
  const sortSetting = sortString ? JSON.parse(sortString) : {}
  const currentScopeSortSetting = sortSetting[scope || ConsultScopesEnum.Open]
  return currentScopeSortSetting
}

const shouldAssignForScope = (scope: string) => {
  if (
    scope === ConsultScopesEnum.AsyncAssignedAwaitingAction ||
    scope === ConsultScopesEnum.EscalationsScope ||
    scope === ConsultScopesEnum.AsyncNewInitialRxExpress
  ) {
    return true
  } else {
    return false
  }
}

const generateDisplayTime = (mins: number) => {
  const hours = Math.floor(mins / 60)
  const minsLeft = mins % 60
  const displayMin = `${minsLeft} minute${minsLeft > 1 ? 's' : ''}`
  if (hours < 1) {
    return displayMin
  } else {
    return `${hours} hour${hours > 1 ? 's' : ''} ${minsLeft > 0 ? displayMin : ''}`
  }
}
const ConsultsTable = (props: ConsultsTableProps) => {
  const synchronous =
    props.scope == ConsultScopesEnum.Synchronous ||
    props.scope == ConsultScopesEnum.SynchronousNeedsAttention
  const patientEscalations = props.scope == ConsultScopesEnum.EscalationsScope
  const router = useRouter()
  const classes = useStyles()
  const [page, setPage] = useState(props.page)
  const [sortBy, setSortBy] = useState(synchronous ? 'scheduledAt' : 'createdAt')
  const [sortDirection, setSortOrder] = useState(SortOrderDirection.ASC)
  const [isLoading, setIsLoading] = useState(props.isLoading)
  const [error, setError] = useState<string | undefined>()
  useEffect(() => {
    const currentScopeSortSetting = getSortingSetting(props.scope)
    if (currentScopeSortSetting) {
      setSortBy(currentScopeSortSetting.sortBy)
      setSortOrder(currentScopeSortSetting.sortDirection)
      props.getConsults(currentScopeSortSetting)
    }
  }, [props.scope])
  useEffect(() => {
    const refreshConsults = async () => {
      if (document.hidden) {
        return
      }
      const scopeSortSetting = getSortingSetting(props.scope)
      if (scopeSortSetting) {
        setSortBy(scopeSortSetting.sortBy)
        setSortOrder(scopeSortSetting.sortDirection)
        await props.getConsults({ page, ...scopeSortSetting })
      } else {
        await props.getConsults({ page, sortBy, sortDirection })
      }
    }

    const refreshInterval = window.setInterval(refreshConsults, props.consultRefreshRate)
    window.addEventListener('visibilitychange', refreshConsults)
    return () => {
      window.removeEventListener('visibilitychange', refreshConsults)
      window.clearInterval(refreshInterval)
    }
  }, [props.getConsults])

  const statusColumn = !patientEscalations
    ? {
        text: 'Status',
        key: 'status',
      }
    : {
        text: 'Patient State',
        key: 'state',
        isSortable: false,
        nestedPath: (data: { customer: CustomerType }) => data.customer.currentAddress,
        formatter: (data: AddressType) => data?.state || '',
      }
  const stateColumn = {
    text: 'State',
    key: 'state',
    nestedPath: (data: { customer: CustomerType }): AddressType[] | undefined => {
      return data.customer?.addresses
    },
    formatter: (data: any) => {
      return `${data && data[0] ? data[0]?.state : ''}`
    },
    isSortable: true,
  }
  const waitTimeColumn = {
    text: 'Wait time',
    key: 'waitTime',
    nestedPath: (data: { consultStatusLog: ConsultStatusLog[] }): string => {
      const logs = data?.consultStatusLog
      if (!logs) {
        return ''
      }
      const sorted = sortByTime({
        data: logs || [],
        attrNames: ['timestamp'],
      })
      return sorted?.[0]?.timestamp
    },
    formatter: (data: string) => {
      if (data?.length > 0) {
        const changedAt = new Date(data)
        const today = new Date()
        const difference = differenceInMinutes(today, changedAt)
        return generateDisplayTime(difference)
      }
      return ''
    },
    isSortable: true,
  }

  const RxWaitTimeColumn = {
    text: 'Wait time',
    key: 'createdAt',
    isSortable: true,
    formatter: (data: string) => {
      const createdAt = new Date(data)
      const today = new Date()
      const difference = differenceInMinutes(today, createdAt)
      return generateDisplayTime(difference)
    },
  }

  const consultLengthColumn = !synchronous
    ? {
        text: 'Consult Length',
        key: 'createdAt',
        isSortable: true,
        formatter: (data: string, status: ConsultStatusEnum) => {
          // Do not display length for finished consults
          if (FinishedStatuses.includes(status)) {
            return '-'
          }
          const createdAt = new Date(data)
          const today = new Date()
          const difference = differenceInDays(today, createdAt)
          return difference > 1 ? `${difference} days` : `< 1 day`
        },
      }
    : {
        text: 'Starting',
        key: 'scheduledAt',
        isSortable: true,
        formatter: (data: string) => {
          return `${formatDate(data, DateFormatEnum.MonthDayFullYear)} (${formatDate(
            data,
            TimeFormatEnum.HourMinutePeriod,
          )})`
        },
      }
  const patientNameColumn = {
    text: 'Patient Name',
    key: 'lastName',
    nestedPath: (data: { customer: CustomerType }): CustomerType => {
      return data.customer
    },
    formatter: (data: any) => {
      return `${data.lastName}, ${data.firstName}`
    },
    isSortable: true,
  }
  const typeColumn = {
    text: 'Consult Type',
    key: 'type',
    isSortable: true,
  }
  const actionColumn = {
    text: 'Action required',
    key: 'actionRequired',
    isSortable: false,
    formatter: actionCellFormatter,
  }
  const lastActivity = {
    text: 'Last Activity',
    key: 'updatedAt',
    isSortable: true,
    formatter: (data: string) => {
      return formatDate(
        data,
        `${DateFormatEnum.MonthDayFullYear} ${TimeFormatEnum.HourMinutePeriod}`,
      )
    },
  }

  const getTableColumns = (scope: string) => {
    switch (scope) {
      case ConsultScopesEnum.AsyncNewInitialRxExpress:
        return [patientNameColumn, stateColumn, RxWaitTimeColumn, lastActivity]
      case ConsultScopesEnum.AsyncAssignedAwaitingAction:
        return [patientNameColumn, stateColumn, waitTimeColumn, lastActivity]
      case ConsultScopesEnum.SynchronousNeedsAttention:
        return [
          patientNameColumn,
          typeColumn,
          actionColumn,
          statusColumn,
          lastActivity,
          consultLengthColumn,
        ]
      default:
        return [patientNameColumn, typeColumn, statusColumn, lastActivity, consultLengthColumn]
    }
  }

  const columns = getTableColumns(props.scope)

  const handleSortRequest = (key: string) => {
    setPage(0)
    let newSortOrder = SortOrderDirection.ASC
    if (sortBy === key && sortDirection === SortOrderDirection.DESC) {
      setSortOrder(SortOrderDirection.ASC)
    } else if (sortBy !== key) {
      setSortBy(key)
      setSortOrder(SortOrderDirection.ASC)
    } else if (sortBy === key) {
      newSortOrder = SortOrderDirection.DESC
      setSortOrder(SortOrderDirection.DESC)
    }
    const sortString = localStorage.getItem(CONSULT_SORTING_SETTING)
    const sortingSet = sortString ? JSON.parse(sortString) : {}
    sortingSet[props.scope || ConsultScopesEnum.Open] = { sortDirection: newSortOrder, sortBy: key }
    localStorage.setItem(CONSULT_SORTING_SETTING, JSON.stringify(sortingSet))
    props.getConsults({ sortDirection: newSortOrder, sortBy: key })
  }

  const handleSelectionError = (reason: Error) => {
    setIsLoading(true)
    props.getConsults({ sortDirection, sortBy, page })
    setIsLoading(false)
    if (!(reason as unknown as Record<string, unknown>).isAxiosError) {
      setError(reason.message)
    } else {
      const error = reason as AxiosError<{ error: string }>
      if (error.response?.status == 409) {
        setError('This consultation was already claimed by a different provider')
      } else if (error.response?.status == 403) {
        setError('This consultation was already assigned to another provider')
      } else {
        setError(error.response?.data?.error || 'An unspecified error has occurred.')
      }
    }
  }

  const TablePagination = ({
    page,
    pageSize,
    totalCount,
  }: {
    page: number
    pageSize: number
    totalCount: number
  }) => {
    const totalPages = Math.ceil(totalCount / pageSize)
    const secondNumber = pageSize * (page + 1)
    const currentlyDisplayed = `${pageSize * page + 1} - ${
      totalCount > secondNumber ? secondNumber : totalCount
    } of ${totalCount}`

    const handlePageChange = (newPage: number) => {
      props.getConsults({ page: newPage, sortBy, sortDirection })
      setPage(newPage)
    }
    return (
      <Alignment justifyContent="space-between" alignItems="center" padding="10px 0 0 0">
        <Alignment>
          <Container>Displaying: {currentlyDisplayed}</Container>
        </Alignment>
        <Alignment alignItems="center">
          <Button
            whiteText
            backgroundColor="primaryGreen"
            type="button"
            onClick={() => handlePageChange(page - 1)}
            disabled={page === 0}
          >
            <Chevron height={24} rotate={180} />
          </Button>
          <Container>
            Page: {page + 1} - {totalPages}
          </Container>
          <Button
            whiteText
            backgroundColor="primaryGreen"
            type="button"
            onClick={() => handlePageChange(page + 1)}
            disabled={page === totalPages - 1}
          >
            <Chevron height={24} />
          </Button>
        </Alignment>
      </Alignment>
    )
  }

  const emptyList = (scope: string) => {
    if (scope === ConsultScopesEnum.AsyncAssignedAwaitingAction) {
      return <div className={classes.text}>{EMPTY_INITIAL}</div>
    }
    if (scope === ConsultScopesEnum.AsyncNewInitialRxExpress) {
      return <div className={classes.text}>{EMPTY_RX_EXPRESS_INITIAL}</div>
    }
    return <span>No Consultations to Display</span>
  }
  const emptyScreen = emptyList(props.scope)
  return (
    <div>
      {!!error && (
        <Modal
          type="centered"
          title="An error occurred trying to reserve this consultation"
          close={() => {
            setError(undefined)
          }}
        >
          <p>{error}</p>
        </Modal>
      )}
      {!isLoading && (
        <>
          {props.data.length > 0 ? (
            <div>
              <Table className={classes.table}>
                <EnhancedTableHead
                  columns={columns}
                  sortDirection={sortDirection}
                  sortBy={sortBy}
                  handleSortRequest={handleSortRequest}
                  classes={classes}
                />
                <EnhancedTableContent
                  consultData={props.data}
                  columns={columns}
                  setIsLoading={setIsLoading}
                  router={router}
                  classes={classes}
                  shouldAssignConsult={shouldAssignForScope(props.scope)}
                  handleError={handleSelectionError}
                />
              </Table>
              <TablePagination
                totalCount={props.totalCount}
                page={page}
                pageSize={props.pageSize}
              />
            </div>
          ) : (
            <Alignment justifyContent="center">{emptyScreen}</Alignment>
          )}
        </>
      )}
      {isLoading && (
        <Alignment justifyContent="center" alignItems="center" height={'50vh'}>
          <CircularProgress className={classes.loading} />
        </Alignment>
      )}
    </div>
  )
}

export default ConsultsTable
