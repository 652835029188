import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import { CheckCircle } from '@styled-icons/boxicons-solid/CheckCircle'
import { ClinicMedical } from '@styled-icons/fa-solid/ClinicMedical'
import { Clipboard } from '@styled-icons/fa-regular/Clipboard'
import { Pencil } from '@styled-icons/boxicons-solid/Pencil'
import { ThreeBars } from '@styled-icons/octicons'
import VaccinesIcon from '@mui/icons-material/Vaccines'
import ChairIcon from '@mui/icons-material/Chair'
import { Search } from '@styled-icons/evaicons-solid/Search'
import { DotsThreeHorizontal } from '@styled-icons/entypo/DotsThreeHorizontal'
import { LabelImportant } from '@styled-icons/material/LabelImportant'
import { VideoCamera } from '@styled-icons/zondicons/VideoCamera'
import { Warning } from '@styled-icons/material/Warning'
import { Notifications } from '@styled-icons/material/Notifications'

import ConsultSearch from 'components/ConsultSearch/ConsultSearch'
import ConsultsTable, {
  getSortingSetting,
} from 'components/ConsultTableWithFiltersComponent/ConsultsTable'
import { ConsultFilterTab } from 'components/ConsultTableWithFiltersComponent/ConsultFilterTab'
import Divider from 'components/Divider'
import useExperiment from 'hooks/useExperiment'
import { AmplitudeFeatureFlagKeys } from 'services/amplitude'
import {
  retrieveConsultCounts,
  retrieveConsults,
  retrieveUnassignedConsults,
  searchConsults,
} from 'services/consultsApi'
import { SubtitleStyles } from 'styles/fonts'
import {
  ConsultType,
  ConsultFilterTabType,
  ConsultScopesEnum,
  PriorityLevelEnum,
} from 'types/consultType'
import { StaffAuthorizations } from 'types/staffType'
import { SortOrderDirection } from 'types/genericTypes'
import { useUserContext } from 'userContext'
import { sortByState } from 'utils/dateTime'
import { orderBy } from 'lodash'
const StyledContainer = styled.div`
  width: 100%;
  display: flex;
`

const FilterContainer = styled.div`
  border-right: solid 1px ${props => props.theme.colors.lightGrey2};
  height: calc(100vh - 85px);
  width: 20%;
`

const TableContainer = styled.div`
  background: ${props => props.theme.colors.lightGrey1};
  height: calc(100vh - 95px);
  overflow: scroll;
  padding: 0 20px;
  width: 80%;
`

const LinkContainer = styled.div<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  color: ${props => (props.isActive ? props.theme.colors.primaryGreen : props.theme.colors.black)};
  text-decoration: none;
  // Needs to be rgba to use opacity, might be able to clean this up in the future.
  background: ${props => (props.isActive ? 'rgba(20, 131, 102, 0.1)' : '')};
  padding: 18px 20px;
`

const StyledLink = styled.a`
  text-decoration: none;
`

const StyledSpan = styled.span`
  padding-left: 10px;
`

const Subtitle = styled.div`
  ${SubtitleStyles};
  color: ${props => props.theme.colors.midGrey1};
  padding-left: 14px;
`
const SubText = styled.div`
  font-family: 'Roboto';
  color: ${props => props.theme.colors.midGrey6};
  font-size: 0.875rem;
  line-height: 143%;
  margin: -16px 0 16px 0;
`
const TitleBox = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
  width: 60%;
  margin: 16px 0;
`
const Title = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  letter-spacing: 0.15px;
  margin: 8px 16px;
`

const ConsultsTableWithFilters = () => {
  const pageSize = 50
  const refreshRate = 60000
  const SearchStatus = 'search'
  const router = useRouter()
  const [consults, setConsults] = useState<Array<ConsultType>>([])
  const [consultTotalCount, setConsultTotalCount] = useState(0)
  const [consultCounts, setConsultCounts] = useState<Record<string, number>>()
  const [isLoading, setIsLoading] = useState(false)
  const user = useUserContext()
  const canViewEscalations = user.user?.authorizations?.includes(
    StaffAuthorizations.ViewEscalations,
  )

  const amplitudeContext = useExperiment()

  const canViewSyncConsults = !!user.user?.healthieId
  const careCallActionsQueueEnabled =
    canViewSyncConsults &&
    amplitudeContext.isVariantActive(AmplitudeFeatureFlagKeys.CareCallsActionQueue, 'on')
  const canShowWaitingRoom = amplitudeContext.isVariantActive(
    AmplitudeFeatureFlagKeys.WaitingRoomQueue,
    'on',
  )
  const canShowRxExpress = amplitudeContext.isVariantActive(
    AmplitudeFeatureFlagKeys.RxExpressQueue,
    'on',
  )
  const handleGetConsults = async ({
    page = 0,
    sortDirection,
    sortBy,
  }: {
    page?: number
    sortDirection: SortOrderDirection
    sortBy: string
  }) => {
    setIsLoading(true)
    // NOTE: sort in FE
    const filteredSortedBy = ['state', 'waitTime'].includes(sortBy) ? 'createdAt' : sortBy
    try {
      const response = await retrieveConsults({
        page,
        pageSize,
        sortBy: filteredSortedBy,
        sortDirection,
        ...router.query,
      })
      let newConsults = response.consults
      if (sortBy === 'state') {
        newConsults = newConsults.sort((a, b) => sortByState(a, b, sortDirection))
      }

      if (sortBy === 'waitTime') {
        const order = sortDirection.toLocaleLowerCase() as 'asc' | 'desc'
        newConsults = orderBy(
          response.consults,
          item =>
            item?.consultStatusLog &&
            item?.consultStatusLog.length > 0 &&
            item?.consultStatusLog?.[0].timestamp
              ? item.consultStatusLog[0].timestamp
              : new Date().toISOString(),
          [order],
        )
      }
      setConsults(newConsults)
      setConsultTotalCount(response.totalCount)
      setIsLoading(false)
    } catch (_e) {
      // do nothing
    }
  }

  const handleGetEscalationConsults = async ({
    page = 0,
    sortDirection,
    sortBy,
  }: {
    page?: number
    sortDirection: SortOrderDirection
    sortBy: string
  }) => {
    setIsLoading(true)
    const response = await retrieveUnassignedConsults({
      page,
      pageSize,
      sortBy,
      sortDirection,
      minPriority: PriorityLevelEnum.Urgent,
    })
    setConsults(response.consults)
    setConsultTotalCount(response.totalCount)
    setIsLoading(false)
  }

  const handleGetConsultCounts = async () => {
    try {
      const counts = await retrieveConsultCounts()
      setConsultCounts(counts)
    } catch (_e) {
      // do nothing
    }
  }

  const handleSearchConsults = async ({
    customerName,
    consultId,
  }: {
    customerName?: string
    consultId?: string
  }) => {
    setIsLoading(true)
    try {
      const response = await searchConsults({ customerName, consultId })
      setConsults(response.consults)
      setConsultTotalCount(response.consults.length)
    } catch {
      // no results found
      // let it fail silently and show table default null text
      setConsults([])
    }
    setIsLoading(false)
  }

  useEffect(() => {
    const refreshCounts = async () => {
      if (document.hidden) {
        return
      }

      await handleGetConsultCounts()
    }

    const refreshInterval = window.setInterval(refreshCounts, refreshRate)
    window.addEventListener('visibilitychange', refreshCounts)
    return () => {
      window.removeEventListener('visibilitychange', refreshCounts)
      window.clearInterval(refreshInterval)
    }
  }, [])

  useEffect(() => {
    handleGetConsultCounts()
    const setting = getSortingSetting((router.query.scope as string) || ConsultScopesEnum.Open)
    if (router.query?.scope === SearchStatus) {
      setConsults([])
      setConsultTotalCount(0)
    } else if (router.query?.scope === ConsultScopesEnum.EscalationsScope) {
      handleGetEscalationConsults(
        setting || { sortBy: 'createdAt', sortDirection: SortOrderDirection.ASC },
      )
    } else if (router.query?.scope === ConsultScopesEnum.Synchronous) {
      handleGetConsults(setting || { sortBy: 'scheduledAt', sortDirection: SortOrderDirection.ASC })
    } else {
      handleGetConsults(setting || { sortBy: 'createdAt', sortDirection: SortOrderDirection.ASC })
    }
  }, [router.query])

  const generateTableTitle = () => {
    switch (router.query?.scope) {
      case ConsultScopesEnum.Urgent:
        return `${ConsultFilterTabType.Urgent} Consultations`
      case ConsultScopesEnum.Prioritized:
        return `${ConsultFilterTabType.Important} Consultations`
      case ConsultScopesEnum.NotStarted:
        return `${ConsultFilterTabType.NotStarted} Consultations`
      case ConsultScopesEnum.Synchronous:
        return ConsultFilterTabType.Live
      case ConsultScopesEnum.SynchronousNeedsAttention:
        return 'Care Calls Requiring Action'
      case ConsultScopesEnum.Started:
        return `${ConsultFilterTabType.InProgress} Consultations`
      case ConsultScopesEnum.Deferred:
        return `${ConsultFilterTabType.Deferred} Consultations`
      case ConsultScopesEnum.Completed:
        return `${ConsultFilterTabType.Completed} Consultations`
      case ConsultScopesEnum.EscalationsScope:
        return ConsultFilterTabType.PatientEscalations
      case ConsultScopesEnum.AsyncAssignedAwaitingAction:
        return `${ConsultFilterTabType.InitialConsult} waiting room`
      case ConsultScopesEnum.AsyncNewInitialRxExpress:
        return `${ConsultFilterTabType.RxExpressInitialConsult} waiting room`
      case SearchStatus:
        return 'Search Consultations'
      case ConsultScopesEnum.Open:
      default:
        return `${ConsultFilterTabType.AllConsultations} Consultations`
    }
  }

  const handleFilterChange = async ({ scope }: { scope?: ConsultScopesEnum | string }) => {
    await router.push({ pathname: '/', query: { scope } })
  }

  const waitingRoomQueue = []
  const styledLinks = [
    {
      onClick: () => handleFilterChange({ scope: ConsultScopesEnum.Open }),
      isActive: router.query.scope == ConsultScopesEnum.Open || router.query.scope == null,
      icon: <ThreeBars size={18} />,
      title: ConsultFilterTabType.AllConsultations,
      count: consultCounts ? consultCounts.open : 0,
    },
    {
      onClick: () => handleFilterChange({ scope: ConsultScopesEnum.Urgent }),
      isActive: router.query.scope == ConsultScopesEnum.Urgent,
      icon: <Warning size={18} />,
      title: ConsultFilterTabType.Urgent,
      count: consultCounts ? consultCounts.urgent : 0,
    },
    {
      onClick: () => handleFilterChange({ scope: ConsultScopesEnum.Prioritized }),
      isActive: router.query.scope == ConsultScopesEnum.Prioritized,
      icon: <LabelImportant size={18} />,
      title: ConsultFilterTabType.Important,
      count: consultCounts ? consultCounts.prioritized : 0,
    },
    ...(canViewSyncConsults
      ? [
          {
            onClick: () => handleFilterChange({ scope: ConsultScopesEnum.Synchronous }),
            isActive: router.query.scope == ConsultScopesEnum.Synchronous,
            icon: <VideoCamera size={18} />,
            title: ConsultFilterTabType.Live,
            count: consultCounts ? consultCounts.synchronous : 0,
            alert: (consultCounts?.imminent || 0) > 0,
          },
        ]
      : []),
    ...(careCallActionsQueueEnabled
      ? [
          {
            onClick: () =>
              handleFilterChange({ scope: ConsultScopesEnum.SynchronousNeedsAttention }),
            isActive: router.query.scope == ConsultScopesEnum.SynchronousNeedsAttention,
            icon: <Notifications size={18} />,
            title: 'Scheduled - Needs Attention',
            alert: (consultCounts?.synchronousNeedsAttention || 0) > 0,
            count: consultCounts ? consultCounts.synchronousNeedsAttention : 0,
          },
        ]
      : []),
    {
      onClick: () => handleFilterChange({ scope: ConsultScopesEnum.NotStarted }),
      isActive: router.query.scope == ConsultScopesEnum.NotStarted,
      icon: <Clipboard size={18} />,
      title: ConsultFilterTabType.NotStarted,
      count: consultCounts ? consultCounts.new + consultCounts.assigned : 0,
    },
    {
      onClick: () => handleFilterChange({ scope: ConsultScopesEnum.Started }),
      isActive: router.query.scope == ConsultScopesEnum.Started,
      icon: <Pencil size={18} />,
      title: ConsultFilterTabType.InProgress,
      count: consultCounts ? consultCounts.started : 0,
    },
    {
      onClick: () => handleFilterChange({ scope: ConsultScopesEnum.Deferred }),
      isActive: router.query.scope == ConsultScopesEnum.Deferred,
      icon: <DotsThreeHorizontal size={18} />,
      title: ConsultFilterTabType.Deferred,
      count: consultCounts ? consultCounts.deferred : 0,
    },

    {
      onClick: () => handleFilterChange({ scope: ConsultScopesEnum.Completed }),
      isActive: router.query.scope == ConsultScopesEnum.Completed,
      icon: <CheckCircle size={18} />,
      title: ConsultFilterTabType.Completed,
      count: undefined,
    },
  ]

  if (canViewEscalations) {
    waitingRoomQueue.push({
      onClick: () => handleFilterChange({ scope: ConsultScopesEnum.EscalationsScope }),
      isActive: router.query.scope == ConsultScopesEnum.EscalationsScope,
      icon: <ClinicMedical size={18} />,
      title: ConsultFilterTabType.PatientEscalations,
      count: consultCounts ? consultCounts.urgentUnassigned : 0,
    })
  }
  if (canShowWaitingRoom) {
    waitingRoomQueue.push({
      onClick: () => handleFilterChange({ scope: ConsultScopesEnum.AsyncAssignedAwaitingAction }),
      isActive: router.query.scope == ConsultScopesEnum.AsyncAssignedAwaitingAction,
      icon: <ChairIcon fontSize={'small'} />,
      title: ConsultFilterTabType.InitialConsult,
      count: consultCounts ? consultCounts.asyncAssignedAwaitingAction : 0,
    })
  }
  if (canShowRxExpress) {
    waitingRoomQueue.push({
      onClick: () => handleFilterChange({ scope: ConsultScopesEnum.AsyncNewInitialRxExpress }),
      isActive: router.query.scope == ConsultScopesEnum.AsyncNewInitialRxExpress,
      icon: <VaccinesIcon size={18} />,
      title: 'RX Express Initial Consults',
      count: consultCounts ? consultCounts.asyncNewInitialRxExpress : 0,
    })
  }
  const generateSubText = (scope: string) => {
    if (scope === ConsultScopesEnum.AsyncAssignedAwaitingAction) {
      return (
        <span>
          This queue displays all available initial consults for new Found members who are ready to
          start their treatment journey. Select a consult to begin their visit.
        </span>
      )
    }
    if (scope === ConsultScopesEnum.AsyncNewInitialRxExpress) {
      return (
        <span>
          This queue displays all available <b>RX Express</b> initial consults for new Found members
          who are ready to start their treatment journey. Select a consult to begin their visit.
        </span>
      )
    }
    return undefined
  }
  const subText = generateSubText(router.query.scope as string)
  const tableComponent = () => {
    if (router.query.scope == ConsultScopesEnum.EscalationsScope && !canViewEscalations) {
      return null
    }
    if (!consults) {
      return null
    }

    return (
      <ConsultsTable
        page={0}
        pageSize={pageSize}
        getConsults={
          router.query.scope == ConsultScopesEnum.EscalationsScope
            ? handleGetEscalationConsults
            : handleGetConsults
        }
        totalCount={consultTotalCount}
        data={consults}
        isLoading={isLoading}
        scope={router.query.scope as string}
        consultRefreshRate={refreshRate}
      />
    )
  }

  return (
    <StyledContainer>
      <FilterContainer>
        <StyledLink onClick={() => handleFilterChange({ scope: SearchStatus })}>
          <LinkContainer>
            <Search size={18} />
            <StyledSpan>Search</StyledSpan>
          </LinkContainer>
        </StyledLink>
        <Divider margin={0} />
        {waitingRoomQueue.length > 0 && (
          <>
            <Title>Waiting Room</Title>
            <List>
              {waitingRoomQueue.map(link => {
                return <ConsultFilterTab key={link.title} {...link} />
              })}
            </List>
          </>
        )}

        <Title>My consults</Title>
        <List>
          {styledLinks.map(link => {
            return <ConsultFilterTab key={link.title} {...link} />
          })}
        </List>
      </FilterContainer>
      <TableContainer>
        <TitleBox>
          <Typography variant="h5">{generateTableTitle()}</Typography>{' '}
          <Subtitle>{consultTotalCount} consultations</Subtitle>
        </TitleBox>
        {subText && <SubText>{subText}</SubText>}
        {router.query.scope === SearchStatus && (
          <ConsultSearch searchConsults={handleSearchConsults} />
        )}
        {tableComponent()}
      </TableContainer>
    </StyledContainer>
  )
}

export default ConsultsTableWithFilters
