import React from 'react'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import theme from 'styles/theme'
import Avatar from '@mui/material/Avatar'
import styled from 'styled-components'
import { ConsultFilterTabType } from 'types/consultType'
interface ConsultFilterTabProps {
  title: string
  onClick: () => void
  isActive: boolean
  icon: JSX.Element
  count?: number
  alert?: boolean
}

const StyledAvatar = styled(Avatar)<{ color?: string }>`
  && {
    width: 24px;
    height: 24px;
    background-color: ${props => (props.color ? props.color : theme.colors.primaryGreen)};
    font-size: 15px;
    line-height: 20px;
  }
`

const StyledListItem = styled(ListItem)`
  cursor: pointer;
  &.Mui-selected {
    background-color: ${theme.colors.lighterGreen} !important;
  }}
  transition: none !important;
`
const StyledListText = styled(ListItemText)`
  overflow: auto;
`

const generateColor = (title: string, count: number, alert: boolean) => {
  if (alert) {
    return theme.colors.red
  }
  if (
    title === ConsultFilterTabType.AllConsultations ||
    title === ConsultFilterTabType.Deferred ||
    title === ConsultFilterTabType.Live
  ) {
    return theme.colors.primaryGreen
  } else if (count > 0) {
    return theme.colors.red
  } else {
    return theme.colors.primaryGreen
  }
}

export const ConsultFilterTab = ({
  onClick,
  isActive,
  icon,
  title,
  count,
  alert,
}: ConsultFilterTabProps): JSX.Element => {
  const countDisplay = count && count > 99 ? '99+' : count
  const color = generateColor(title, count ?? 0, !!alert)
  return (
    <StyledListItem
      sx={{ background: isActive ? theme.colors.background : theme.colors.white }}
      onClick={onClick}
    >
      <ListItemIcon style={{ justifyContent: 'center' }}>{icon}</ListItemIcon>
      <StyledListText primary={title} />
      {count !== undefined && <StyledAvatar color={color}>{countDisplay}</StyledAvatar>}
    </StyledListItem>
  )
}
