import React from 'react'
import styled from 'styled-components'
import { getColorByConsultType } from '../../styles/utils/getColorByConsultType'
import { ConsultTypeEnum } from '../../types/consultType'
import { TableCell } from '@mui/material'
interface ConsultTableTypeCellProps {
  children: React.ReactElement
  type: ConsultTypeEnum
  priority?: number
}

const StyledConsultType = styled.div<{ type: ConsultTypeEnum; priority?: number }>`
  color: ${props => props.theme.colors.white};
  background: ${props =>
    getColorByConsultType({ type: props.type, priority: props.priority, props })};
  border-radius: 4px;
  padding: 5px;
  text-align: center;
`

const ConsultTableTypeCell = ({ children, ...props }: ConsultTableTypeCellProps) => {
  return (
    <TableCell>
      <StyledConsultType {...props}>{children}</StyledConsultType>
    </TableCell>
  )
}

export default ConsultTableTypeCell
